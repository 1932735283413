import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Cart, ICart } from '../models/cart.model';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private cart = new BehaviorSubject<ICart>(new Cart());
  currentCart = this.cart.asObservable();


  private spinner = new BehaviorSubject<string>("close");
  currentSpinner = this.spinner.asObservable();

  constructor() { }

  changeCart(message: ICart) {
    this.cart.next(message);
  }

  changeSpinner(message: string) {
    this.spinner.next(message);
  }

}
