import { Component, OnInit, ViewChild, Input } from '@angular/core';

import {
  SwiperComponent, SwiperDirective, SwiperConfigInterface,
  SwiperScrollbarInterface, SwiperPaginationInterface
} from 'ngx-swiper-wrapper';

import { Observable } from 'rxjs';
// import { ObservableMedia } from '@angular/flex-layout';
import { map } from 'rxjs/operators';

import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-popular-items',
  templateUrl: './popular-items.component.html',
  styleUrls: ['./popular-items.component.scss']
})
export class PopularItemsComponent implements OnInit {

  watcher: Subscription;
  activeMediaQuery = '';

  @Input() productos: any[];

  public slides = [
    'First slide',
    'Second slide',
    'Third slide',
    'Fourth slide',
    'Fifth slide',
    'Sixth slide'
  ];

  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    // slidesPerView: 4,
    spaceBetween: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: false
  };

  // private scrollbar: SwiperScrollbarInterface = {
  //   el: '.swiper-scrollbar',
  //   hide: false,
  //   draggable: true
  // };

  // private pagination: SwiperPaginationInterface = {
  //   el: '.swiper-pagination',
  //   clickable: true,
  //   hideOnClick: false
  // };

  // @ViewChild(SwiperComponent, { static: false }) componentRef: SwiperComponent;
  // @ViewChild(SwiperDirective, { static: false }) directiveRef: SwiperDirective;

  constructor(mediaObserver: MediaObserver) {
    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      this.activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';
      if (change.mqAlias == 'xs') {
        this.config.slidesPerView = 2;
        this.config.spaceBetween = 6;
      }else{
        this.config.slidesPerView = 4;
        this.config.spaceBetween = 6;
      }
    });
  }


  public onIndexChange(index: number) {
  }

  ngOnInit() {
    
  }
}
