export interface IOferta {
  id?: number;
  nemonico?: string;
  descripcion?: string;
  estado?: string;
  url?: string;
  titulo?: string;
  productoId?: number;
  categoriaId?: number;
  coleccionId?: number;
  valor?: number;
  categoria?: string;
}

export class Oferta implements IOferta {
  constructor(
    public id?: number,
    public nemonico?: string,
    public descripcion?: string,
    public estado?: string,
    public url?: string,
    public titulo?: string,
    public productoId?: number,
    public categoriaId?: number,
    public coleccionId?: number,
    public valor?: number,
    public categoria?: string
  ) {}
}
