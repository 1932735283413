import { Component, OnInit } from '@angular/core';
import { IOferta, Oferta } from 'src/app/models/oferta.model';
import { OfertaService } from 'src/app/services/oferta.service';
import { filter, map } from 'rxjs/operators';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBarRef, SimpleSnackBar, MatSnackBar } from '@angular/material';
import { IProducto } from 'src/app/models/producto.model';
import { ColeccionService } from 'src/app/services/coleccion.service';
import { IColeccion } from 'src/app/models/coleccion.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { SharedService } from 'src/app/services/shared.service';
import { LOGIN_ALREADY_USED_TYPE } from 'src/app/app.constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  mensaje: string = 'Mensaje desde padre';
  ofertas: IOferta[] = new Array();
  productos: IProducto[] = new Array();

  interval = 3000;
  public slides: IOferta[] = new Array();
  public ofertasDeracha: IOferta[] = new Array();
  public ofertasInferior: IOferta = new Oferta();
  public ofertas15_99: IOferta = new Oferta();
  public ofertas20_porct: IOferta = new Oferta();

  emailForm: FormGroup;
  // error: string;
  success: boolean;
  errorUserExists: boolean = false;

  constructor(protected ofertaService: OfertaService,
    private formBuilder: FormBuilder,
    protected coleccionService: ColeccionService,
    protected accountService: AccountService,
    private snackBar: MatSnackBar,
    private sharedService:SharedService) { }

  public ngOnInit() {
    //esta en app.component
    // this.accountService.identity().then((account: Account) => {
    //   this.account = account;
    // });
    // this.registerAuthenticationSuccess();


    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]]
    });

    this.loadAll();
    this.getProductos();
  }

  // registerAuthenticationSuccess() {
  //   this.eventManager.subscribe('authenticationSuccess', message => {
  //     this.accountService.identity().then(account => {
  //       this.account = account;
  //     });
  //   });
  // }

  loadAll() {
    this.ofertaService
      .query()
      .pipe(
        filter((res: HttpResponse<IOferta[]>) => res.ok),
        map((res: HttpResponse<IOferta[]>) => res.body)
      )
      .subscribe(
        (res: IOferta[]) => {
          this.ofertas = res;
          this.addSlide();
          this.addOfertasDeracha();
          this.getOfertasInferior();
          this.getOfertas15_99();
          this.getOfertas20_porct();
        },
        (res: HttpErrorResponse) => this.onError(res.message)
      );
  }

  getProductos() {
    this.coleccionService.findByNemonico('TOP_10')
      .pipe(
        filter((res: HttpResponse<IColeccion>) => res.ok),
        map((res: HttpResponse<IColeccion>) => res.body)
      )
      .subscribe((res: IColeccion) => {
        if (res && res.productos) {
          this.productos = res.productos;
        }
      });
  }

  public addSlide() {
    this.ofertas.forEach(item => {
      if (item.categoria === 'PRINCIPAL') {
        this.slides.push(item);
      }
    });
  }

  public addOfertasDeracha() {
    this.ofertas.forEach(item => {
      if (item.categoria === 'PRINCIPAL_DERECHA' && this.ofertasDeracha.length < 2) {
        this.ofertasDeracha.push(item);
      }
    });
  }

  public getOfertasInferior() {
    this.ofertas.forEach(item => {
      if (item.categoria === 'PRINCIPAL_INFERIOR') {
        this.ofertasInferior = item;
      }
    });
  }

  public getOfertas15_99() {
    this.ofertas.forEach(item => {
      if (item.categoria === 'DESCUENTOS' && item.nemonico === 'OFERTA_15.99') {
        this.ofertas15_99 = item;
      }
    });
  }

  public getOfertas20_porct() {
    this.ofertas.forEach(item => {
      if (item.categoria === 'DESCUENTOS' && item.nemonico === 'OFERTA_20_porct') {
        this.ofertas20_porct = item;
      }
    });
  }

  registrar() {
    this.errorUserExists = false;
    if (this.emailForm.valid) {
      this.sharedService.changeSpinner("open");
      this.accountService.saveAccountWithEmail({ email: this.emailForm.get('email').value })
        .subscribe(() => {
          this.success = true;
          this.emailForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]]
          });
          this.sharedService.changeSpinner("close");
        },
          response => this.processError(response)
        )
    }
  }

  protected onError(errorMessage: string) {
    this.openSnackBar(errorMessage, "Ok");
  }

  openSnackBar(message: string, action: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, action, {
      verticalPosition: 'top',
      duration: 5000,
    });
  }

  private processError(response: HttpErrorResponse) {
    debugger;
    this.success = null;
    // this.error = 'ERROR';
    if (response.status === 400 && response.error.mensaje === LOGIN_ALREADY_USED_TYPE) {
      this.errorUserExists = true;
    }
    this.sharedService.changeSpinner("close");
    this.openSnackBar("Este correo electrónico ya se ha agregado a nuestra lista.", "Ok");
  }

}
