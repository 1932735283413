import { IProducto } from './producto.model';

export interface ICategoria {
  id?: number;
  nemonico?: string;
  descripcion?: string;
  estado?: string;
  url?: string;
  path?: string;
  titulo?: string;
  categoriaId?: number;
  categorias?: ICategoria[];
  productos?: IProducto[];
}

export class Categoria implements ICategoria {
  constructor(
    public id?: number,
    public nemonico?: string,
    public descripcion?: string,
    public estado?: string,
    public url?: string,
    public path?: string,
    public titulo?: string,
    public categoriaId?: number,
    public categorias?: ICategoria[],
    public prodcutos?: IProducto[]
  ) {}
}
