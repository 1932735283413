import { Component, OnInit, Input } from '@angular/core';
import { IProducto } from 'src/app/models/producto.model';
import { ICart } from 'src/app/models/cart.model';
import { IProductoCart } from 'src/app/models/producto.cart.model';
import { LocalStorageService } from 'ngx-webstorage';
import { SharedService } from 'src/app/services/shared.service';
import { MatSnackBarRef, SimpleSnackBar, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @Input() producto: IProducto;

  constructor(private localStorage: LocalStorageService,
    private sharedService: SharedService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
  }


  addCart() {
    debugger;
    let cart: ICart = this.localStorage.retrieve('productosCart');
    let cantidad: number = 1;
    if (cart) {
      cart.cantidad = cart.cantidad + cantidad;
      cart.total = cart.total + (cantidad * this.producto.precio);

      let productoCart: IProductoCart = {
        producto: this.producto,
        cantidad: cantidad,
        total: cantidad * this.producto.precio
      }
      let existe: boolean = false;
      cart.productos.forEach(p => {
        if (p.producto.id === productoCart.producto.id) {
          p.cantidad = p.cantidad + productoCart.cantidad;
          p.total = p.cantidad * productoCart.producto.precio;
          existe = true;
        }
      })

      if (!existe) {
        cart.productos.push(productoCart);
      }
    } else {
      cart = {
        cantidad: cantidad,
        total: cantidad * this.producto.precio,
        productos: new Array()
      }
      let productoCart: IProductoCart = {
        producto: this.producto,
        cantidad: cantidad,
        total: cantidad * this.producto.precio
      }
      cart.productos.push(productoCart);
    }
    this.localStorage.store('productosCart', cart);
    this.sharedService.changeCart(cart);

    this.openSnackBar(`El producto ${this.producto.nombre} se ha agregado al carrito.`, "Ok");

  }


  openSnackBar(message: string, action: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, action, {
      verticalPosition: 'top',
      duration: 5000,
    });
  }

}
