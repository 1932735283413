export interface IAccount {

  activated?: boolean;
  authorities?: string[];
  email?: string;
  password?: string;
  nombres?: string;
  apellidos?: string;
  telefono?: string;
  direccion?: string;
  langKey?: string;
  imageUrl?: string;
  resetKey?: boolean;
  estado?: string;
  cambioClave?: boolean;
  provincia?: string;
  ciudad?: string;
}


export class Account implements IAccount {
  constructor(
    public activated?: boolean,
    public authorities?: string[],
    public email?: string,
    public password?: string,
    public nombres?: string,
    public apellidos?: string,
    public telefono?: string,
    public direccion?: string,
    public langKey?: string,
    public imageUrl?: string,
    public estado?: string,
    public cambioClave?: boolean,
    public resetKey?: boolean,
    public provincia?: string,
    public ciudad?: string
  ) { }
  
}
