import { IDetalleVenta } from './detalle-venta.model';


export interface IVenta {
  id?: number;
  nroDocumento?: string;
  proveedor?: string;
  cantidad?: number;
  iva?: number;
  subtotal?: number;
  total?: number;
  estado?: string;
  // fechaCreacion?: Moment;
  formaPago?: string;
  payId?: string;
  // fechaPago?: Moment;
  payerInfo?: string;
  tokenPago?: string;
  activeToken?: boolean;
  observacion?: string;
  detalleVentaDTO?: IDetalleVenta[];
  usuarioId?: number;
  usuarioEmail?: string;
}

export class Venta implements IVenta {
  constructor(
    public id?: number,
    public nroDocumento?: string,
    public proveedor?: string,
    public cantidad?: number,
    public iva?: number,
    public subtotal?: number,
    public total?: number,
    public estado?: string,
    // public fechaCreacion?: Moment,
    public formaPago?: string,
    public payId?: string,
    // public fechaPago?: Moment,
    public payerInfo?: string,
    public tokenPago?: string,
    public activeToken?: boolean,
    public observacion?: string,
    public detalleVentaDTO?: IDetalleVenta[],
    public usuarioId?: number,
    public usuarioEmail?: string
  ) {
    this.activeToken = this.activeToken || false;
  }
}
