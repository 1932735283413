import { IProducto } from './producto.model';

export interface IColeccion {
  id?: number;
  nemonico?: string;
  descripcion?: string;
  estado?: string;
  url?: string;
  titulo?: string;
  productos?: IProducto[];
}

export class Coleccion implements IColeccion {
  constructor(
    public id?: number,
    public nemonico?: string,
    public descripcion?: string,
    public estado?: string,
    public url?: string,
    public titulo?: string,
    public productos?: IProducto[]
  ) {}
}
