import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';


@Component({
  selector: 'app-antes-checkout',
  templateUrl: './antes-checkout.component.html',
  styleUrls: ['./antes-checkout.component.scss']
})
export class AntesCheckoutComponent implements OnInit {

  // mensaje:string = "";

  constructor(private dialogRef: MatDialogRef<AntesCheckoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router) { }

  ngOnInit() {
  }

  ngAfterContentInit() {
  //   debugger;
  //   this.mensaje = this.data.mensaje;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
