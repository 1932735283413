import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { IAccount, Account } from 'src/app/models/account.model';
import { MatSnackBarRef, SimpleSnackBar, MatSnackBar } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  updateForm: FormGroup;
  authenticationError: boolean;
  account: Account = new Account();

  constructor(private formBuilder: FormBuilder,
    private accountService: AccountService,
    private snackBar: MatSnackBar) { }


  ngOnInit() {

    this.updateForm = this.formBuilder.group({
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      telefono: ['', [Validators.required, Validators.minLength(10), Validators.pattern('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$')]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
      direccion: [''],
    });


    this.accountService.identity().then(account => {
      debugger;
      this.account = account;
      this.updateForm = this.formBuilder.group({
        nombres: [this.account.nombres, Validators.required],
        apellidos: [this.account.apellidos, Validators.required],
        telefono: [this.account.telefono, [Validators.required, Validators.minLength(10), Validators.pattern('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$')]],
        email: [this.account.email, [Validators.required, Validators.email, Validators.maxLength(50)]],
        direccion: [this.account.direccion],
      });
    });

    //this.key = this.route.snapshot.queryParamMap.get('key');



    
  }


  private llenarUser(): IAccount {
    return {
      ...new Account(),
      activated: true,
      email: this.updateForm.get(['email']).value,
      nombres: this.updateForm.get(['nombres']).value,
      apellidos: this.updateForm.get(['apellidos']).value,
      telefono: this.updateForm.get(['telefono']).value,
      direccion: this.updateForm.get(['direccion']).value,
      langKey: "es",
      resetKey: false,
      estado: "ACT",
      cambioClave: false
    }
  }

  update() {
    const user: IAccount = this.llenarUser();
    this.accountService
      .save(user)
      .subscribe(() => {
        //this.updateForm.reset();
        // this.router.navigate(['/login']);
        this.openSnackBar("Su perfil se actualizó exitosamente en Exaess- Tenda Online.", "OK");
      },
        response => this.processError(response)
      )
  }

  private processError(response: HttpErrorResponse) {
    this.openSnackBar(JSON.stringify(response), "Ok");
  }

  openSnackBar(message: string, action: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, action, {
      verticalPosition: 'top',
      duration: 5000,
    });
  }

}
