import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { JhiLanguageService } from 'ng-jhipster';
import { JhiLanguageHelper } from 'src/app/language/language.helper';


@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'coleccion',
        loadChildren: './coleccion/coleccion.module#ColeccionModule'
      },
      {
        path: 'producto',
        loadChildren: './producto/producto.module#ProductoModule'
      }
    ])
  ],
  declarations: [],
  entryComponents: [],
  providers: [{ provide: JhiLanguageService, useClass: JhiLanguageService }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AdminModule {
  constructor(private languageService: JhiLanguageService, private languageHelper: JhiLanguageHelper) {
    this.languageHelper.language.subscribe((languageKey: string) => {
      if (languageKey !== undefined) {
        this.languageService.changeLanguage(languageKey);
      }
    });
  }
 }
