import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { Account, IAccount } from 'src/app/models/account.model';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { ICart, Cart } from 'src/app/models/cart.model';
import { LocalStorageService } from 'ngx-webstorage';
import { IVenta, Venta } from 'src/app/models/venta.model';
import { VentaService } from 'src/app/services/venta.service';
import { DetalleVenta } from 'src/app/models/detalle-venta.model';
import { MatDialog, MatSnackBarRef, SimpleSnackBar, MatSnackBar } from '@angular/material';
import { PopUpNroVentaComponent } from '../pop-up-nro-venta/pop-up-nro-venta.component';
import { HttpErrorResponse } from '@angular/common/http';
import { SharedService } from 'src/app/services/shared.service';
import { Router } from '@angular/router';
import { AntesCheckoutComponent } from '../antes-checkout/antes-checkout.component';
import { DespuesCheckoutComponent } from '../despues-checkout/despues-checkout.component';

declare let paypal: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  account: Account = new Account();
  checkoutForm: FormGroup;
  venta: IVenta = new Venta();
  cart: ICart = new Cart();
  costoEnvio: number = 0;
  total: number = 0;
  public payPalConfig?: IPayPalConfig;
  checkout: boolean = false;
  codigoDescuento:string = "";


  @ViewChild('linkWhatsapp', { static: true }) linkWhatsapp: ElementRef;

  constructor(private formBuilder: FormBuilder,
    private accountService: AccountService,
    private localStorage: LocalStorageService,
    private ventaService: VentaService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private sharedService: SharedService,
    private router: Router) { }

  ngOnInit() {
    this.cart = this.localStorage.retrieve('productosCart');
    if (this.cart && this.cart.productos) {
      this.venta.total = this.cart.total;
      this.venta.cantidad = this.cart.cantidad;
      this.venta.detalleVentaDTO = new Array();
      this.cart.productos.forEach(item => {
        let detalleVenta: DetalleVenta = {
          cantidad: item.cantidad,
          precio: item.producto.precio,
          total: item.total,
          productoId: item.producto.id
        };
        this.venta.detalleVentaDTO.push(detalleVenta);
      });
    }


    this.checkoutForm = this.formBuilder.group({
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      telefono: ['', [Validators.required, Validators.minLength(10), Validators.pattern('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$')]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
      direccion: [''],
      provincia: [''],
      ciudad: [''],
      informacionAdicional: ['']
    });


    this.accountService.identity().then(account => {
      this.account = account;
      this.checkoutForm = this.formBuilder.group({
        nombres: [this.account && this.account.nombres ? this.account.nombres : '', Validators.required],
        apellidos: [this.account && this.account.apellidos ? this.account.apellidos : '', Validators.required],
        telefono: [this.account && this.account.telefono ? this.account.telefono : '', [Validators.required, Validators.minLength(10), Validators.pattern('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$')]],
        email: [this.account && this.account.email ? this.account.email : '', [Validators.required, Validators.email, Validators.maxLength(50)]],
        direccion: [this.account && this.account.direccion ? this.account.direccion : '', Validators.required],
        provincia: [this.account && this.account.provincia ? this.account.provincia : '', Validators.required],
        ciudad: [this.account && this.account.ciudad ? this.account.ciudad : '', Validators.required],
        informacionAdicional: ['']
      });
    });

    this.initConfig();
  }

  private llenarUser(): IAccount {
    return {
      ...new Account(),
      activated: true,
      email: this.checkoutForm.get(['email']).value,
      nombres: this.checkoutForm.get(['nombres']).value,
      apellidos: this.checkoutForm.get(['apellidos']).value,
      telefono: this.checkoutForm.get(['telefono']).value,
      direccion: this.checkoutForm.get(['direccion']).value,
      provincia: this.checkoutForm.get(['provincia']).value,
      ciudad: this.checkoutForm.get(['ciudad']).value,
      langKey: "es",
      resetKey: false,
      estado: "ACT",
      cambioClave: false
    }
  }
  actualizarInformacion() {
    debugger;
    this.sharedService.changeSpinner("open");
    const user: IAccount = this.llenarUser();
    this.accountService
      .updateAccountWithCompra(user)
      .subscribe(() => {
        //this.checkoutForm.reset();
        if (this.cart && this.cart.productos) {
          this.checkout = true;
        }
        //this.router.navigate(['/login']);
        this.openSnackBar("La información se envío exitosamente.", "OK");
        // window.location.hash = '#topElement';
        this.sharedService.changeSpinner("close");
      },
        response => this.processError(response)
      )

  }


  validarCodigo(){
    console.log("-----------> " + this.codigoDescuento);
  }

  private initConfig(): void {
    //sandbox: 'AcfVe5E44eSS-uXM8yQTIvGx92CshhIg0vGQ7JNSOr1SNpUO3NTrdiH72qSLgxzxV0z5GWTPu4p1G0Ng',
    //production: 'AeppNk4cenBhR6j-1bIwaHBKtzMTrsiScojVifliFalePZrR7P-Tvj4jO8h4MOAJGuZQ5Boo2xBp9Flg'
    if (this.cart) {
      this.costoEnvio = this.cart.total <= 45 ? 4.5 : 0;
      this.total = this.cart.total + this.costoEnvio;
    }
    let total: string = this.cart ? String(this.cart.total) : "0";
    this.payPalConfig = {
      currency: 'USD',
      clientId: 'AeppNk4cenBhR6j-1bIwaHBKtzMTrsiScojVifliFalePZrR7P-Tvj4jO8h4MOAJGuZQ5Boo2xBp9Flg',
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'USD',
              value: total,
              breakdown: {
                item_total: {
                  currency_code: 'USD',
                  value: total
                }
              }
            }//,
            // items: [
            //   {
            //     name: 'Enterprise Subscription',
            //     quantity: '1',
            //     category: 'DIGITAL_GOODS',
            //     unit_amount: {
            //       currency_code: 'USD',
            //       value: '302.75',
            //     },
            //   }
            // ]
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (payment) => {
        this.sharedService.changeSpinner("open");
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', payment);
        //this.showSuccess = true;

        this.venta.payId = payment.id;
        this.venta.estado = "PAGADO";
        this.venta.formaPago = "PAYPAL";
        this.venta.payerInfo = payment.payer.email_address;
        this.venta.activeToken = false;
        this.venta.observacion = this.checkoutForm.get(['informacionAdicional']).value;
        this.venta.usuarioEmail = this.checkoutForm.get(['email']).value;

        this.ventaService.pagoPaypalCompra(this.venta).subscribe(resp => {
          if (resp) {
            this.venta = resp.body;
            this.localStorage.store('productosCart', undefined);
            this.sharedService.changeCart(undefined);
            this.sharedService.changeSpinner("close");

            let dialogRef = this.dialog.open(PopUpNroVentaComponent, {
              width: '550px',
              data: { venta: this.venta }
            });
            dialogRef.afterClosed().subscribe(() => { });

            // this.mensajeRegistro = true;
            //   this.openSnackBar("Su pedido se ha realizado con éxito", "OK", );
          }
        });
        console.log(payment);

      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
      },
      onError: err => {
        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      },
    };
  }

  pagarWhatsapp() {

    if(!this.venta.id){


      let dialogRef = this.dialog.open(AntesCheckoutComponent, {
        width: '550px',
        disableClose: true,
        data: { mensaje: "Despues de realizar el pedido, no olvides enviar el <b>número de pedido generado</b>, a nuestro whatsapp 593 992318932" }
      });
      dialogRef.afterClosed().subscribe(result => {
        if("SI" === result){
          console.log("========================================>");
  
          this.sharedService.changeSpinner("open");
          this.venta.estado = "POR_PAGAR";
          this.venta.formaPago = "WHATSAPP";
          this.venta.activeToken = false;
          this.venta.observacion = this.checkoutForm.get(['informacionAdicional']).value;
          this.venta.usuarioEmail = this.checkoutForm.get(['email']).value;
  
          this.ventaService.pagoWhatsappCompra(this.venta).subscribe(resp => {
            if (resp) {
              this.venta = resp.body;
              this.localStorage.store('productosCart', undefined);
              this.sharedService.changeCart(undefined);
              this.linkWhatsapp.nativeElement.href = 'https://api.whatsapp.com/send?phone=593992318932&text=Hola,%20me%20ayuda%20gestionando%20mi%20pedido:%20Nro.%20' + this.venta.nroDocumento;
              let el: HTMLElement = this.linkWhatsapp.nativeElement;
              el.click();
          //     this.router.navigateByUrl('/home');
  
  
              let dialogRef2 = this.dialog.open(DespuesCheckoutComponent, {
                width: '550px',
                disableClose: true,
                data: { mensaje: this.venta.nroDocumento}
              });
  
              dialogRef2.afterClosed().subscribe(() => {
                this.router.navigateByUrl('/home');
               });
  
  
            }
            this.sharedService.changeSpinner("close");
          },
            response => this.processError(response));
        }
      });


    }




    
  }

  private processError(response: HttpErrorResponse) {
    this.openSnackBar(JSON.stringify(response), "Ok");
  }

  openSnackBar(message: string, action: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, action, {
      verticalPosition: 'top',
      duration: 5000,
    });
  }
}
