import { NgModule } from '@angular/core';
import { ExaessSharedLibsModule } from './shared-libs.module';
import { HasAnyAuthorityDirective } from 'src/app/directives/has-any-authority.directive';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  declarations: [HasAnyAuthorityDirective],
  imports: [
    ExaessSharedLibsModule,
 FormsModule, AngularEditorModule, HttpClientModule
  ],
  exports: [ExaessSharedLibsModule, HasAnyAuthorityDirective,
FormsModule, AngularEditorModule, HttpClientModule],
})
export class SharedModule { 
  static forRoot() {
    return {
      ngModule: SharedModule
    };
  }
}
