import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HomeComponent } from './components/user/home/home.component';
import { CategoriasComponent } from './components/user/categorias/categorias.component';
import { ViewProductComponent } from './components/user/view-product/view-product.component';
import { CheckoutComponent } from './components/user/checkout/checkout.component';
import { ContactComponent } from './components/user/contact/contact.component';
import { LoginComponent } from './components/user/login/login.component';
import { IProducto, Producto } from './models/producto.model';
import { ProductoService } from './services/producto.service';
import { Observable, of } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { filter, map } from 'rxjs/operators';
import { ICategoria, Categoria } from './models/categoria.model';
import { CategoriaService } from './services/categoria.service';
import { UpdateAccountComponent } from './components/user/update-account/update-account.component';
import { IColeccion, Coleccion } from './models/coleccion.model';
import { ColeccionService } from './services/coleccion.service';
import { ResetPasswordComponent } from './components/user/reset-password/reset-password.component';
import { ActivateComponent } from './components/user/activate/activate.component';
import { PerfilComponent } from './components/user/perfil/perfil.component';
import { UserRouteAccessGuard } from './guards/user-route-access.guard';
import { CartComponent } from './components/user/cart/cart.component';


@Injectable({ providedIn: 'root' })
export class ProductoResolve implements Resolve<IProducto> {
  constructor(private service: ProductoService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IProducto> {
    const id = route.params['id'] ? route.params['id'] : null;
    if (id) {
      return this.service.find(id).pipe(
        filter((response: HttpResponse<Producto>) => response.ok),
        map((producto: HttpResponse<Producto>) => producto.body)
      );
    }
    return of(new Producto());
  }
}


@Injectable({ providedIn: 'root' })
export class CategoriaResolve implements Resolve<ICategoria> {
  constructor(private service: CategoriaService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICategoria> {
    const id = route.params['id'] ? route.params['id'] : null;
    if (id) {
      return this.service.findWithProducts(id).pipe(
        filter((response: HttpResponse<Categoria>) => response.ok),
        map((categoria: HttpResponse<Categoria>) => categoria.body)
      );
    }
    return of(new Categoria());
  }
}


@Injectable({ providedIn: 'root' })
export class ColeccionResolve implements Resolve<IColeccion> {
  constructor(private service: ColeccionService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IColeccion> {
    const id = route.params['id'] ? route.params['id'] : null;
    if (id) {
      return this.service.find(id).pipe(
        filter((response: HttpResponse<Coleccion>) => response.ok),
        map((coleccion: HttpResponse<Coleccion>) => coleccion.body)
      );
    }
    return of(new Coleccion());
  }
}


@Injectable({ providedIn: 'root' })
export class SearchResolve implements Resolve<IProducto[]> {
  constructor(private service: ProductoService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IProducto[]> {
    debugger;
    const search = route.params['filter'] ? route.params['filter'] : null;
    if (filter) {
      return this.service.getSearch(search).pipe(
        filter((response: HttpResponse<Producto[]>) => response.ok),
        map((productos: HttpResponse<Producto[]>) => productos.body)
      );
    }
    return of(new Array);
  }
}


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  // { path: 'categorias', component: CategoriasComponent },
  // { path: 'view-product', component: ViewProductComponent },
  { path: 'cart', component: CartComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'login', component: LoginComponent },
  { path: 'update-account', component: UpdateAccountComponent },
  { path: 'reset/finish', component: ResetPasswordComponent, data: { pageTitle: 'Contraseña' } },
  { path: 'activate', component: ActivateComponent, data: { pageTitle: 'Activación' } },
  { path: 'perfil', component: PerfilComponent, canActivate: [UserRouteAccessGuard], data: { pageTitle: 'Activación' } },
  // 

  {
    path: ':id/categorias',
    component: CategoriasComponent,
    resolve: {
      categoria: CategoriaResolve
    }
  },

  {
    path: ':id/view-product',
    component: ViewProductComponent,
    resolve: {
      producto: ProductoResolve
    }
  },
  {
    path: ':id/descuentos_15.99_usd',
    component: CategoriasComponent,
    resolve: {
      coleccion: ColeccionResolve
    }
  },
  {
    path: ':id/descuentos_20_porct',
    component: CategoriasComponent,
    resolve: {
      coleccion: ColeccionResolve
    }
  },
  {
    path: ':filter/search',
    component: CategoriasComponent,
    resolve: {
      productos: SearchResolve
    }
  },



  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes, { useHash: true , scrollPositionRestoration: 'top'}
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
