import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { ICategoria, Categoria } from 'src/app/models/categoria.model';
import { IProducto } from 'src/app/models/producto.model';
import { CategoriaService } from 'src/app/services/categoria.service';
import { filter, map } from 'rxjs/operators';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss']
})
export class CategoriasComponent implements OnInit {

  panelOpenState = false;
  pageActual: number = 1;
  categoria: ICategoria = new Categoria();
  clasificaciones: ICategoria[] = new Array();
  tituloOferta: string = "Ofertas";

  productos: IProducto[] = new Array();

  // seasons: string[] = ['Hombre', 'Mujer', 'Niños', 'Niñas'];

  constructor(protected activatedRoute: ActivatedRoute,
    protected categoriaService: CategoriaService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ categoria }) => {
      this.productos = categoria.productos;
      this.pageActual = 1;

      if (!categoria.categoriaId) {
        this.categoria = categoria;
      } else {
        this.categoriaService.find(categoria.categoriaId).subscribe(resp => {
          this.categoria = resp.body;
        });
      }

      this.categoriaService.listarClasificacion(categoria.categoriaId ? categoria.categoriaId : categoria.id)
        .pipe(
          filter((res: HttpResponse<ICategoria[]>) => res.ok),
          map((res: HttpResponse<ICategoria[]>) => res.body)
        )
        .subscribe(
          (res: ICategoria[]) => {
            this.clasificaciones = res;
          },
          (res: HttpErrorResponse) => this.onError(res.message)
        );
    });

    this.activatedRoute.data.subscribe(({ coleccion }) => {
      debugger;
      if (coleccion) {
        this.productos = coleccion.productos;
        this.tituloOferta = coleccion.descripcion;
        this.pageActual = 1;
      }
    });

    this.activatedRoute.data.subscribe(({ productos }) => {
      debugger;
      if (Array.isArray(productos)) {
        this.productos = productos;
        this.pageActual = 1;
      }
    });
  }


  ngAfterViewInit() {
    debugger;
    window.scrollTo(0, 0);
  }


  protected onError(errorMessage: string) {
    this.openSnackBar(errorMessage, "Ok");
  }

  openSnackBar(message: string, action: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, action, {
      verticalPosition: 'top',
      duration: 5000,
    });
  }

}
