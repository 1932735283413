import { Component, OnInit, ViewChild } from '@angular/core';

import {
  SwiperComponent, SwiperDirective, SwiperConfigInterface,
  SwiperScrollbarInterface, SwiperPaginationInterface
} from 'ngx-swiper-wrapper';
import { ActivatedRoute } from '@angular/router';
import { Producto, IProducto } from 'src/app/models/producto.model';
import { ProductoService } from 'src/app/services/producto.service';
import { filter, map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ProductoCart, IProductoCart } from 'src/app/models/producto.cart.model';
import { LocalStorageService } from 'ngx-webstorage';
import { ICart, Cart } from 'src/app/models/cart.model';
import { SharedService } from 'src/app/services/shared.service';
import { MatSnackBarRef, SimpleSnackBar, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.scss']
})
export class ViewProductComponent implements OnInit {

  cantidadForm: FormGroup;
  producto: Producto = new Producto();
  productos: IProducto[] = new Array();
  cart:ICart = new Cart();
  public type: string = 'component';
  public disabled: boolean = false;

  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 4,
    spaceBetween: 16,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: false
  };

  private scrollbar: SwiperScrollbarInterface = {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true
  };

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false
  };

  @ViewChild(SwiperComponent, { static: false }) componentRef: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef: SwiperDirective;

  constructor(protected activatedRoute: ActivatedRoute,
    private productoService: ProductoService,
    private formBuilder: FormBuilder,
    private localStorage: LocalStorageService,
    private sharedService:SharedService,
    private snackBar: MatSnackBar) { }

  // public toggleType() {
  //   this.type = (this.type === 'component') ? 'directive' : 'component';
  // }

  public onIndexChange(index: number) {
    console.log('Swiper index: ', index);
  }

  ngOnInit() {

    this.cantidadForm = this.formBuilder.group({
      cantidad: ['1', [Validators.required, CustomValidator.numeric]]
    });


    this.config.slidesPerView = 4;
    this.activatedRoute.data.subscribe(({ producto }) => {
      this.producto = producto;
      this.getProductos();
    });
  }

  getProductos() {
    this.productoService.getTopTenProductos(this.producto.clasificacionId)
      .pipe(
        filter((res: HttpResponse<IProducto[]>) => res.ok),
        map((res: HttpResponse<IProducto[]>) => res.body)
      )
      .subscribe((res: IProducto[]) => {
        if (res) {
          this.productos = res;
        }
      })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  restar() {
    let cantidad: number = this.cantidadForm.get(['cantidad']).value;
    if (cantidad && cantidad > 1) {
      this.cantidadForm = this.formBuilder.group({
        cantidad: [+cantidad - 1, [Validators.required, CustomValidator.numeric]]
      });
    }
  }

  sumar() {
    let cantidad: number = this.cantidadForm.get(['cantidad']).value;
    if (this.producto.cantidad >= (+cantidad + 1)) {
      this.cantidadForm = this.formBuilder.group({
        cantidad: [+cantidad + 1, [Validators.required, CustomValidator.numeric]]
      });
    } else {
      //mensaje de alerta 
    }
  }


  ckeckout() {
    let cart: ICart = this.localStorage.retrieve('productosCart');
    let cantidad: number = +this.cantidadForm.get(['cantidad']).value;
    if (cart) {
      cart.cantidad = cart.cantidad + cantidad;
      cart.total = cart.total + (cantidad * this.producto.precio);

      let productoCart: IProductoCart = {
        producto: this.producto,
        cantidad: cantidad,
        total: cantidad * this.producto.precio
      }
      let existe: boolean = false;
      cart.productos.forEach(p => {
        if (p.producto.id === productoCart.producto.id) {
          p.cantidad = p.cantidad + productoCart.cantidad;
          p.total = p.cantidad * productoCart.producto.precio;
          existe = true;
        }
      })

      if (!existe) {
        cart.productos.push(productoCart);
      }
    } else {
      cart = {
        cantidad: cantidad,
        total: cantidad * this.producto.precio,
        productos: new Array()
      }
      let productoCart: IProductoCart = {
        producto: this.producto,
        cantidad: cantidad,
        total: cantidad * this.producto.precio
      }
      cart.productos.push(productoCart);
    }
    this.cart = cart;
    this.localStorage.store('productosCart', this.cart);
  }

  addCart(){
    this.ckeckout();
    this.sharedService.changeCart(this.cart);
    this.openSnackBar(`El producto ${this.producto.nombre} se ha agregado al carrito.`, "Ok");
  }

  openSnackBar(message: string, action: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, action, {
      verticalPosition: 'top',
      duration: 5000,
    });
  }


}


export class CustomValidator {
  // Number only validation
  static numeric(control: AbstractControl) {
    let val = control.value;

    if (val === null || val === '') return null;

    if (!val.toString().match(/^[0-9]+(\.?[0-9]+)?$/)) return { 'invalidNumber': true };

    return null;
  }
}
