import { IImagen } from './imagen.model';
import { IColeccion } from './coleccion.model';

export interface IProducto {
  id?: number;
  codigoBarras?: string;
  nombre?: string;
  descripcion?: string;
  precio?: number;
  url?: string;
  precioFinal?: number;
  precioMinimo?: number;
  cantidad?: number;
  descuento?: number;
  precioCompra?: number;
  caracteristicas?: string;
  imagenes?: IImagen[];
  categoriaId?: number;
  clasificacionId?: number;
  proveedorId?: number;
  coleccions?: IColeccion[];
}

export class Producto implements IProducto {
  constructor(
    public id?: number,
    public codigoBarras?: string,
    public nombre?: string,
    public descripcion?: string,
    public precio?: number,
    public url?: string,
    public precioFinal?: number,
    public precioMinimo?: number,
    public cantidad?: number,
    public descuento?: number,
    public precioCompra?: number,
    public caracteristicas?: string,
    public imagenes?: IImagen[],
    public categoriaId?: number,
    public clasificacionId?: number,
    public proveedorId?: number,
    public coleccions?: IColeccion[]
  ) {}
}
