import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBarRef, SimpleSnackBar, MatSnackBar } from '@angular/material';
import { SharedService } from 'src/app/services/shared.service';
import { LOGIN_ALREADY_USED_TYPE } from 'src/app/app.constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  emailForm: FormGroup;
  success: boolean;
  errorUserExists: boolean = false;
  
  constructor(private formBuilder: FormBuilder,
    protected accountService: AccountService,
    private snackBar: MatSnackBar,
    private sharedService:SharedService) { }

  ngOnInit() {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]]
    });
  }


  registrar() {
    this.errorUserExists = false;
    if (this.emailForm.valid) {
      this.sharedService.changeSpinner("open");
      this.accountService.saveAccountWithEmail({ email: this.emailForm.get('email').value })
        .subscribe(() => {
          this.success = true;
          this.emailForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]]
          });
          this.sharedService.changeSpinner("close");
        },
          response => this.processError(response)
        )
    }
  }

  openSnackBar(message: string, action: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, action, {
      verticalPosition: 'top',
      duration: 5000,
    });
  }

  private processError(response: HttpErrorResponse) {
    this.success = null;
    // this.error = 'ERROR';
    if (response.status === 400 && response.error.mensaje === LOGIN_ALREADY_USED_TYPE) {
      this.errorUserExists = true;
    }
    this.sharedService.changeSpinner("close");
    this.openSnackBar("Este correo electrónico ya se ha agregado a nuestra lista.", "Ok");
  }

  
}
