import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { StateStorageService } from 'src/app/services/state-storage.service';
import { IAccount, Account } from 'src/app/models/account.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBarRef, SimpleSnackBar, MatSnackBar } from '@angular/material';
import { AccountService } from 'src/app/services/account.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  returnUrl: string;
  registerForm: FormGroup;
  success: boolean;
  restablecerPassword: boolean;
  restablecerForm: FormGroup;
  successRestablecer: boolean;

  constructor( private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private loginService: LoginService,
        private stateStorageService: StateStorageService,
        private accountService: AccountService,
        private snackBar: MatSnackBar,
        private sharedService:SharedService) { }


  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');

    this.loginForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
        password: ['', [Validators.required, Validators.minLength(5)]]
    });

    this.registerForm = this.formBuilder.group({
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      telefono: ['', [Validators.required, Validators.minLength(10), Validators.pattern('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$')]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
      password: ['', [Validators.required, Validators.minLength(5)]],
      passwordConfirm: ['', [Validators.required, confirmPassword]],
      direccion: [''],
    });

    this.restablecerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]]
  });

  }



  login() {
    this.sharedService.changeSpinner("open");
    this.loginService
      .login({
        username: this.loginForm.get('email').value,
        password: this.loginForm.get('password').value,
        rememberMe: true
      })
      .then(() => {
        debugger;
        // this.activeModal.dismiss('login success');
        if (this.router.url === '/register' || this.router.url === '/login' || /^\/activate\//.test(this.router.url) || /^\/reset\//.test(this.router.url)) {
          this.router.navigate(['']);
        }

        // previousState was set in the authExpiredInterceptor before being redirected to login modal.
        // since login is successful, go to stored previousState and clear previousState
        const redirect = this.stateStorageService.getUrl();
        if (redirect) {
          this.stateStorageService.storeUrl(null);
          this.router.navigateByUrl(redirect);
        }

        this.sharedService.changeSpinner("close");
      })
      .catch(() => {
        this.processError("Verifique sus credenciales e intente nuevamente.");
      });
  }

  private llenarUser(): IAccount {
    return {
      ...new Account(),
      activated: false,
      email: this.registerForm.get(['email']).value,
      nombres: this.registerForm.get(['nombres']).value,
      apellidos: this.registerForm.get(['apellidos']).value,
      telefono: this.registerForm.get(['telefono']).value,
      direccion: this.registerForm.get(['direccion']).value,
      password: this.registerForm.get(['password']).value,
      langKey: "es",
      resetKey: false,
      estado: "ACT",
      cambioClave: false
    }
  }
  
  registar() {
    debugger;
    this.sharedService.changeSpinner("open");
    const user: IAccount = this.llenarUser();
    this.accountService
      .register(user)
      .subscribe(() => {
        this.registerForm.reset();
        this.success = true;
        this.sharedService.changeSpinner("close");
      },
        response => this.processError("¡Correo electrónico ya está en uso! Por favor elije otro.")
      )
  }


  restablece() {
    this.sharedService.changeSpinner("open");
    this.accountService
      .restablecer({
        email: this.restablecerForm.get('email').value
      })
      .subscribe(() => {
        this.restablecerForm.reset();
        this.successRestablecer = true;
        this.sharedService.changeSpinner("close");
      },
        response => this.processError("¡El correo no está registrado! Por favor revisa e intenta de nuevo")
      )
  }

  
  private processError(response: string) {
    this.success = false;
    this.successRestablecer = false;
    this.sharedService.changeSpinner("close");
    this.openSnackBar(response, "Ok");
  }

  openSnackBar(message: string, action: string): MatSnackBarRef<SimpleSnackBar> {
    this.sharedService.changeSpinner("close");
    return this.snackBar.open(message, action, {
      verticalPosition: 'top',
      duration: 5000,
    });
  }
}


function confirmPassword(control: AbstractControl) {
  if (!control.parent || !control) {
    return;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return;
  }

  if (passwordConfirm.value === '') {
    return;
  }

  if (password.value !== passwordConfirm.value) {
    return {
      passwordsNotMatch: true
    };
  }
}

