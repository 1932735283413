import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from '../app.constants';
import { IVenta } from '../models/venta.model';
// import { IVenta } from 'app/shared/model/venta.model';

type EntityResponseType = HttpResponse<IVenta>;
type EntityArrayResponseType = HttpResponse<IVenta[]>;

@Injectable({ providedIn: 'root' })
export class VentaService {
  public resourceUrl = SERVER_API_URL + 'api/ventas';

  constructor(protected http: HttpClient) {}

  create(venta: IVenta): Observable<EntityResponseType> {
    // const copy = this.convertDateFromClient(venta);
    return this.http
      .post<IVenta>(this.resourceUrl, venta, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => res));
  }

  pagoPaypalCompra(venta: IVenta): Observable<EntityResponseType> {
    return this.http
    .post<IVenta>(`${this.resourceUrl}/paypal`, venta, { observe: 'response' })
    .pipe(map((res: EntityResponseType) => res));
  }

  pagoWhatsappCompra(venta: IVenta): Observable<EntityResponseType> {
    return this.http
    .post<IVenta>(`${this.resourceUrl}`, venta, { observe: 'response' })
    .pipe(map((res: EntityResponseType) => res));
  }


  // update(venta: IVenta): Observable<EntityResponseType> {
  //   const copy = this.convertDateFromClient(venta);
  //   return this.http
  //     .put<IVenta>(this.resourceUrl, copy, { observe: 'response' })
  //     .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  // }

  // find(id: number): Observable<EntityResponseType> {
  //   return this.http
  //     .get<IVenta>(`${this.resourceUrl}/${id}`, { observe: 'response' })
  //     .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  // }

  // query(req?: any): Observable<EntityArrayResponseType> {
  //   const options = createRequestOption(req);
  //   return this.http
  //     .get<IVenta[]>(this.resourceUrl, { params: options, observe: 'response' })
  //     .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  // }

  // delete(id: number): Observable<HttpResponse<any>> {
  //   return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  // }

  // protected convertDateFromClient(venta: IVenta): IVenta {
  //   const copy: IVenta = Object.assign({}, venta, {
  //     fechaCreacion: venta.fechaCreacion != null && venta.fechaCreacion.isValid() ? venta.fechaCreacion.toJSON() : null,
  //     fechaPago: venta.fechaPago != null && venta.fechaPago.isValid() ? venta.fechaPago.toJSON() : null
  //   });
  //   return copy;
  // }

  // protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
  //   if (res.body) {
  //     res.body.fechaCreacion = res.body.fechaCreacion != null ? moment(res.body.fechaCreacion) : null;
  //     res.body.fechaPago = res.body.fechaPago != null ? moment(res.body.fechaPago) : null;
  //   }
  //   return res;
  // }

  // protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
  //   if (res.body) {
  //     res.body.forEach((venta: IVenta) => {
  //       venta.fechaCreacion = venta.fechaCreacion != null ? moment(venta.fechaCreacion) : null;
  //       venta.fechaPago = venta.fechaPago != null ? moment(venta.fechaPago) : null;
  //     });
  //   }
  //   return res;
  // }
}
