import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeComponent } from './components/user/home/home.component';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IgxCarouselModule } from 'igniteui-angular';

import {NgxPaginationModule} from 'ngx-pagination'; 
import { NgxWebstorageModule } from 'ngx-webstorage';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';


import { FooterComponent } from './components/layout/footer/footer.component';
import { ProductComponent } from './components/layout/product/product.component';
import { CategoriasComponent } from './components/user/categorias/categorias.component';
import { ViewProductComponent } from './components/user/view-product/view-product.component';


import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { PopularItemsComponent } from './components/layout/popular-items/popular-items.component';
import { ItemsCartComponent } from './components/layout/items-cart/items-cart.component';
import { CheckoutComponent } from './components/user/checkout/checkout.component';
import { ContactComponent } from './components/user/contact/contact.component';
import { LoginComponent } from './components/user/login/login.component';
import { AdminModule } from './components/admin/admin.module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgJhipsterModule } from 'ng-jhipster';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { SharedModule } from './components/shared/shared.module';

import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { environment } from 'src/environments/environment';
import { UpdateAccountComponent } from './components/user/update-account/update-account.component';
import { ResetPasswordComponent } from './components/user/reset-password/reset-password.component';
import { ActivateComponent } from './components/user/activate/activate.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { PerfilComponent } from './components/user/perfil/perfil.component';

import { NgxPayPalModule } from 'ngx-paypal';
import { PopUpNroVentaComponent } from './components/user/pop-up-nro-venta/pop-up-nro-venta.component';
 

import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { CartComponent } from './components/user/cart/cart.component';
import { SpinnerComponent } from './components/layout/spinner/spinner.component';
import { AntesCheckoutComponent } from './components/user/antes-checkout/antes-checkout.component';
import { DespuesCheckoutComponent } from './components/user/despues-checkout/despues-checkout.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  // spaceBetween: 16,
  slidesPerView: 1,
  // centeredSlides: true
};

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor:"red",
  bgsOpacity:0.5,
  bgsPosition:"bottom-right",
  bgsSize:60,
  bgsType:"ball-scale-multiple",
  blur:5,
  delay:0,
  fgsColor:"rgba(51, 51, 51, 0.8)",
  fgsPosition:"center-center",
  fgsSize:60,
  fgsType:"ball-scale-multiple",
  gap:24,
  logoPosition:"center-center",
  logoSize:120,
  logoUrl:"",
  masterLoaderId:"master",
  overlayBorderRadius:"0",
  overlayColor:"rgba(160,149,149,0.8)",
  //pbColor:"red",
  pbDirection:"ltr",
  pbThickness:3,
  hasProgressBar:true,
  text:"",
  textColor:"#FFFFFF",
  textPosition:"center-center",
  maxTime:-1,
  minTime:300
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    ProductComponent,
    CategoriasComponent,
    ViewProductComponent,
    PopularItemsComponent,
    ItemsCartComponent,
    CheckoutComponent,
    ContactComponent,
    LoginComponent,
    UpdateAccountComponent,
    ResetPasswordComponent,
    ActivateComponent,
    PerfilComponent,
    PopUpNroVentaComponent,
    CartComponent,
    SpinnerComponent,
    AntesCheckoutComponent,
    DespuesCheckoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    // AngularFontAwesomeModule,
    FontAwesomeModule,
    IgxCarouselModule,
    NgxPaginationModule,
    SwiperModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),

    SharedModule.forRoot(),
  

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    NgJhipsterModule.forRoot({
      // set below to true to make alerts look like toast
      alertAsToast: false,
      alertTimeout: 5000,
      i18nEnabled: true,
      defaultI18nLang: 'en'
    }),


    NgxPayPalModule,

    AdminModule,
    AppRoutingModule,


    //firebase
    AngularFireModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),

    
    // NgxUiLoaderModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig)
    
  ],
  entryComponents:[PopUpNroVentaComponent, AntesCheckoutComponent, DespuesCheckoutComponent],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
}
