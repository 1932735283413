import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { createRequestOption } from '../shared/request-util';
import { IProducto } from '../models/producto.model';
import { SERVER_API_URL } from '../app.constants';

type EntityResponseType = HttpResponse<IProducto>;
type EntityArrayResponseType = HttpResponse<IProducto[]>;

@Injectable({
  providedIn: 'root'
})
export class ProductoService {
  public resourceUrl = SERVER_API_URL + 'api/productos';

  constructor(protected http: HttpClient) {}

  create(producto: IProducto): Observable<EntityResponseType> {
    return this.http
      .post<IProducto>(this.resourceUrl, producto, { observe: 'response' });
  }

  update(producto: IProducto): Observable<EntityResponseType> {
    return this.http
      .put<IProducto>(this.resourceUrl, producto, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IProducto>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(): Observable<EntityArrayResponseType> {
    //const options = createRequestOption(req);
    return this.http
      .get<IProducto[]>(this.resourceUrl, {observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  getTopTenProductos(clasificacionId: number): Observable<EntityArrayResponseType> {
    return this.http
      .get<IProducto[]>(`${this.resourceUrl}/toptenclasificacion/${clasificacionId}`, {observe: 'response' });
  }

  getSearch(filter: string): Observable<EntityArrayResponseType> {
    return this.http
      .get<IProducto[]>(`${this.resourceUrl}/search/${filter}`, {observe: 'response' });
  }

}