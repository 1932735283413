import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {DragDropModule} from '@angular/cdk/drag-drop';

import {
  MatFormFieldModule, MatInputModule, MatCheckboxModule, MatButtonModule, MatCardModule, MatIconModule, MatSidenavModule, MatSlideToggleModule, MatDividerModule, MatToolbarModule, MatTableModule, MatPaginatorModule, MatSortModule, MatMenuModule, MatDialogModule, MatSnackBarModule, MatSelectModule, MatTooltipModule, MatButtonToggleModule, MatRadioModule, MatProgressSpinnerModule, MatAutocompleteModule,
  MatListModule, MatExpansionModule
} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FontAwesomeModule,
    MatFormFieldModule,
    FormsModule, 
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSelectModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatListModule,
    MatExpansionModule,
    DragDropModule    
  ],
  exports: [CommonModule, FlexLayoutModule, FontAwesomeModule, 
    FormsModule, 
    ReactiveFormsModule,
    MatIconModule, 
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSelectModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatListModule,
    MatExpansionModule,
    DragDropModule],
})
export class ExaessSharedLibsModule {
  static forRoot() {
    return {
      ngModule: ExaessSharedLibsModule
    };
  }
}
