import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Venta, IVenta } from 'src/app/models/venta.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pop-up-nro-venta',
  templateUrl: './pop-up-nro-venta.component.html',
  styleUrls: ['./pop-up-nro-venta.component.scss']
})
export class PopUpNroVentaComponent implements OnInit {

  venta:IVenta = new Venta();
  linkPedido: string = "https://api.whatsapp.com/send?phone=593992318932&text=Hola,%20me%20ayuda%20gestionando%20mi%20pedido:%20Nro.%20";

  constructor(private dialogRef: MatDialogRef<PopUpNroVentaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router) { }

  ngOnInit() {
  }

  ngAfterContentInit() {
    debugger;
    this.venta = this.data.venta;
    this.linkPedido = this.linkPedido + this.venta.nroDocumento;
  }

  dismiss() {
    this.dialogRef.close(null);
    this.router.navigateByUrl('/home');
  }
}
