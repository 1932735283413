import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-despues-checkout',
  templateUrl: './despues-checkout.component.html',
  styleUrls: ['./despues-checkout.component.scss']
})
export class DespuesCheckoutComponent implements OnInit {

  mensaje:string = "";

  constructor(private dialogRef: MatDialogRef<DespuesCheckoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router) { }

  ngOnInit() {
  }

  ngAfterContentInit() {
    this.mensaje = this.data.mensaje;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
