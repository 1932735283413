import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICategoria } from '../models/categoria.model';
import { createRequestOption } from '../shared/request-util';
import { SERVER_API_URL } from '../app.constants';

type EntityResponseType = HttpResponse<ICategoria>;
type EntityArrayResponseType = HttpResponse<ICategoria[]>;

@Injectable({ providedIn: 'root' })
export class CategoriaService {
  public resourceUrl = SERVER_API_URL + 'api/categorias';

  constructor(protected http: HttpClient) {}

  create(categoria: ICategoria): Observable<EntityResponseType> {
    return this.http.post<ICategoria>(this.resourceUrl, categoria, { observe: 'response' });
  }

  update(categoria: ICategoria): Observable<EntityResponseType> {
    return this.http.put<ICategoria>(this.resourceUrl, categoria, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<ICategoria>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(): Observable<EntityArrayResponseType> {
    return this.http.get<ICategoria[]>(this.resourceUrl, { observe: 'response' });
  }

  listarClasificacion(categoriaId: number): Observable<EntityArrayResponseType> {
    return this.http.get<ICategoria[]>(`${this.resourceUrl}/clasificacion/${categoriaId}`, { observe: 'response' });
  }

  findWithProducts(id: number): Observable<EntityResponseType> {
    return this.http.get<ICategoria>(`${this.resourceUrl}/productos/${id}`, { observe: 'response' });
  }

  // findByClasificacionWithProducts(id: number): Observable<EntityResponseType> {
  //   return this.http.get<ICategoria>(`${this.resourceUrl}/productos/clasificacion/${id}`, { observe: 'response' });
  // }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }
}
