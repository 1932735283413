import { Component } from '@angular/core';
import { LoginService } from './services/login.service';
import { Router } from '@angular/router';
import { AccountService } from './services/account.service';
import { JhiEventManager } from 'ng-jhipster';
import { Account } from './models/account.model';
import { SharedService } from './services/shared.service';
import { ICart, Cart } from './models/cart.model';
import { LocalStorageService } from 'ngx-webstorage';
// import { NgxSpinnerService } from 'ngx-spinner';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ICategoria } from './models/categoria.model';
import { CategoriaService } from './services/categoria.service';
// import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs/operators';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Exaess';
  opened: any;
  interval= 3000;
  account: Account = new Account();
  cart:ICart = new Cart();
  spinner:boolean = false;
  search:string = "";

  clasificaciones: ICategoria[] = new Array();

  public slides: any[] = [];
  constructor(private loginService: LoginService,
    private router: Router,
    private accountService: AccountService,
    private eventManager: JhiEventManager,
    private sharedService:SharedService,
    private localStorage: LocalStorageService,
    protected categoriaService: CategoriaService,
    private ngxService: NgxUiLoaderService,
    private snackBar: MatSnackBar){//public translate: TranslateService) { 
    // translate.addLangs(['es', 'en']);
    // translate.setDefaultLang('es');
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|es/) ? browserLang : 'es');
  }

  public ngOnInit() {
    this.ngxService.start();

    this.sharedService.currentSpinner.subscribe(spinner => {
      if(spinner === "open"){
        this.spinner = true;
      }else{
        this.spinner = false;
      }
    });

    this.accountService.identity().then((account: Account) => {
      this.account = account;
    });
    this.registerAuthenticationSuccess();
    this.addNewSlide();

    this.sharedService.currentCart.subscribe(cart => {
      if (cart && cart.total) {
        this.cart = cart;
      } else {
        this.cart = this.localStorage.retrieve('productosCart');
      }
    });
  }

  registerAuthenticationSuccess() {
    this.eventManager.subscribe('authenticationSuccess', message => {
      this.accountService.identity().then(account => {
        this.account = account;
      });
    });
  }

  public addNewSlide() {
    this.slides.push(
      {
        description: "30+ Material-based Angular components to code speedy web apps faster.",
        heading: "Ignite UI for Angular",
        image: "https://www.infragistics.com/angular-demos/assets/images/carousel/slide1-angular.png"
      },
      {
        description: "A complete JavaScript UI library empowering you to build data-rich responsive web apps.",
        heading: "Ignite UI for Javascript",
        image: "https://www.infragistics.com/angular-demos/assets/images/carousel/slide2-ignite.png"
      },
      {
        description: "Build full-featured business apps with the most versatile set of ASP.NET AJAX UI controls",
        heading: "Ultimate UI for ASP.NET",
        image: "https://www.infragistics.com/angular-demos/assets/images/carousel/slide3-aspnet.png"
      }
    );
  }

  isAuthenticated() {
    return this.accountService.isAuthenticated();
  }

  applyFilter(event: any){
    if (event.keyCode == 13 && event.key === 'Enter' && this.search) {
      this.router.navigate(['/', this.search, 'search']);
    }
  }

  logout() {
    this.account = null;
    this.loginService.logout();
    this.router.navigate(['']);
  }

  seleccionarCategoria(categoriaId: number){
    console.log("---------------------------------------------> ");
    this.categoriaService.listarClasificacion(categoriaId)
    .pipe(
      filter((res: HttpResponse<ICategoria[]>) => res.ok),
      map((res: HttpResponse<ICategoria[]>) => res.body)
    )
    .subscribe(
      (res: ICategoria[]) => {
        this.clasificaciones = res;
      },
      (res: HttpErrorResponse) => this.onError(res.message)
    );
  }


  protected onError(errorMessage: string) {
    this.openSnackBar(errorMessage, "Ok");
  }

  openSnackBar(message: string, action: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, action, {
      verticalPosition: 'top',
      duration: 5000,
    });
  }

}
