import { Injectable, isDevMode } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from '../services/account.service';
import { StateStorageService } from '../services/state-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserRouteAccessGuard implements CanActivate {

  constructor(
    private router: Router,
    private accountService: AccountService,
    private stateStorageService: StateStorageService
  ){}


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
debugger;
      const authorities = next.data['authorities'];

      return this.checkLogin(authorities, state.url);
  }
  
  checkLogin(authorities: string[], url: string): Promise<boolean> {
    debugger;
    return this.accountService.identity().then(account => {
      if (!authorities || authorities.length === 0) {
        return true;
      }

      if (account) {
        const hasAnyAuthority = this.accountService.hasAnyAuthority(authorities);
        if (hasAnyAuthority) {
          return true;
        }
        if (isDevMode()) {
          console.error('User has not any of required authorities: ', authorities);
        }
        this.router.navigate(['accessdenied']);
        return false;
      }

      this.stateStorageService.storeUrl(url);
      // this.loginModalService.open();
      this.router.navigate(['login']);
      return false;
    });
  }

}
