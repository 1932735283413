import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { IAccount, Account } from 'src/app/models/account.model';
import { MatSnackBarRef, SimpleSnackBar, MatSnackBar } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-update-account',
  templateUrl: './update-account.component.html',
  styleUrls: ['./update-account.component.scss']
})
export class UpdateAccountComponent implements OnInit {

  updateForm: FormGroup;
  // returnUrl: string;
  key: string;
  authenticationError: boolean;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private snackBar: MatSnackBar,
    private sharedService:SharedService) { }


  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.key = params['key'];
    });
    //this.key = this.route.snapshot.queryParamMap.get('key');



    this.updateForm = this.formBuilder.group({
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      telefono: ['', [Validators.required, Validators.minLength(10), Validators.pattern('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$')]],
      email: [this.key ? this.key : '', [Validators.required, Validators.email, Validators.maxLength(50)]],
      password: ['', [Validators.required, Validators.minLength(5)]],
      passwordConfirm: ['', [Validators.required, confirmPassword]],
      direccion: [''],
    });
  }


  private llenarUser(): IAccount {
    return {
      ...new Account(),
      activated: true,
      email: this.updateForm.get(['email']).value,
      nombres: this.updateForm.get(['nombres']).value,
      apellidos: this.updateForm.get(['apellidos']).value,
      telefono: this.updateForm.get(['telefono']).value,
      direccion: this.updateForm.get(['direccion']).value,
      password: this.updateForm.get(['password']).value,
      langKey: "es",
      resetKey: false,
      estado: "ACT",
      cambioClave: false
    }
  }

  update() {
    this.sharedService.changeSpinner("open");
    const user: IAccount = this.llenarUser();
    this.accountService
      .updateAccountWithEmail(user)
      .subscribe(() => {
        this.updateForm.reset();
        this.router.navigate(['/login']);
        this.sharedService.changeSpinner("close");
        this.openSnackBar("Su registro a Exaess- Tenda Online, fue exitoso.", "OK");
      },
        response => this.processError(response)
      );
  }

  private processError(response: HttpErrorResponse) {
    this.sharedService.changeSpinner("close");
    this.openSnackBar(JSON.stringify(response), "Ok");
  }

  openSnackBar(message: string, action: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, action, {
      verticalPosition: 'top',
      duration: 5000,
    });
  }

}


function confirmPassword(control: AbstractControl) {
  if (!control.parent || !control) {
    return;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return;
  }

  if (passwordConfirm.value === '') {
    return;
  }

  if (password.value !== passwordConfirm.value) {
    return {
      passwordsNotMatch: true
    };
  }
}

