import { Component, OnInit, Input } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { IProductoCart } from 'src/app/models/producto.cart.model';
import { IProducto } from 'src/app/models/producto.model';
import { ICart, Cart } from 'src/app/models/cart.model';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-items-cart',
  templateUrl: './items-cart.component.html',
  styleUrls: ['./items-cart.component.scss']
})
export class ItemsCartComponent implements OnInit {

  @Input() toMenu: boolean;
  @Input() toCheckout: boolean;
  cart: ICart = new Cart();

  productos: IProducto[] = new Array();

  constructor(private localStorage: LocalStorageService,
    private sharedService: SharedService) { }

  ngOnInit() {
    // this.cart = this.localStorage.retrieve('productosCart');
    // if(this.cart){
    //   this.productos = this.cart.productos;
    // }


    this.sharedService.currentCart.subscribe(cart => {
      if (cart && cart.total) {
        this.cart = cart;
        if (this.cart) {
          this.productos = this.cart.productos;
        }
      } else {
        this.cart = this.localStorage.retrieve('productosCart');
        if (this.cart) {
          this.productos = this.cart.productos;
        }
      }
    });


  }

  deleteItemCart(itemCart: IProductoCart) {
    debugger;
    let cart: ICart = this.localStorage.retrieve('productosCart');
    if (cart && cart.productos) {
      // cart.productos.forEach(item => {
      //   if(item.producto.id === itemCart.producto.id){
      //     cart.productos.slice
      //   }
      // })

      const foundIndex = this.cart.productos.findIndex(x => x.producto.id === itemCart.producto.id);
      this.cart.productos.splice(foundIndex, 1);

      // const foundIndex = this.cart.productos.findIndex(item => {
      //   if(item.producto.id === itemCart.producto.id){
      //     this.cart.productos.splice(foundIndex, 1);
      this.cart.cantidad = this.cart.cantidad - itemCart.cantidad;
      this.cart.total = this.cart.total - itemCart.total;

      this.localStorage.store('productosCart', this.cart);
      this.sharedService.changeCart(this.cart);
      //   }
      // });


    }
  }
}
