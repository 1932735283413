import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { IAccount, Account } from 'src/app/models/account.model';
import { MatSnackBarRef, SimpleSnackBar, MatSnackBar } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

 
  resetPasswordForm: FormGroup;
  key: string;
  authenticationError: boolean;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private snackBar: MatSnackBar) { }


  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.key = params['key'];
    });
    //this.key = this.route.snapshot.queryParamMap.get('key');



    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(5)]],
      passwordConfirm: ['', [Validators.required, confirmPassword]]
    });
  }



  update() {
    debugger;
    let aux = this.resetPasswordForm.get('password').value;
    this.accountService
      .resetPassword({
        key: this.key,
        newPassword: this.resetPasswordForm.get('password').value
      })
      .subscribe(() => {
        this.resetPasswordForm.reset();
        this.router.navigate(['/login']);
        this.openSnackBar("Su contraseña se restablecio exitosamente, fue exitoso.", "OK");
      },
        response => this.processError(response)
      )
  }

  private processError(response: HttpErrorResponse) {
    this.openSnackBar(JSON.stringify(response), "Ok");
  }

  openSnackBar(message: string, action: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, action, {
      verticalPosition: 'top',
      duration: 5000,
    });
  }

}


function confirmPassword(control: AbstractControl) {
  if (!control.parent || !control) {
    return;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return;
  }

  if (passwordConfirm.value === '') {
    return;
  }

  if (password.value !== passwordConfirm.value) {
    return {
      passwordsNotMatch: true
    };
  }
}

