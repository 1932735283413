// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application

// export const VERSION = process.env.VERSION;
// export const DEBUG_INFO_ENABLED: boolean = !!process.env.DEBUG_INFO_ENABLED;
// export const SERVER_API_URL = "http://192.168.1.2:8081/"; //process.env.SERVER_API_URL;
export const SERVER_API_URL = "https://exaess-33ac4a58432e.herokuapp.com/"
// export const BUILD_TIMESTAMP = process.env.BUILD_TIMESTAMP;

export const ITEMS_PER_PAGE = 20;

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';

export const TOKEN_AUTH_USERNAME = 'mitomediapp';
export const TOKEN_AUTH_PASSWORD = 'mito89codex';

export const BASE_PATH_IMAGES = '/productos';

//error
export const LOGIN_ALREADY_USED_TYPE = 'emailExists';