import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSnackBar, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ICart } from 'src/app/models/cart.model';
import { LocalStorageService } from 'ngx-webstorage';
import { IProductoCart } from 'src/app/models/producto.cart.model';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {



  constructor(private snackBar: MatSnackBar,
    private router: Router,
    private localStorage: LocalStorageService,
    private sharedService: SharedService) { }

  displayedColumns: string[] = ['url', 'nombre', 'precio', 'cantidad', 'total', 'accion'];
  dataSource = new MatTableDataSource<any>();

  ngOnInit() {

    let cart: ICart = this.localStorage.retrieve('productosCart');
    if (cart && cart.productos) {
      this.dataSource = new MatTableDataSource<any>(cart.productos);
    }

  }

  getTotal() {
    return this.dataSource.data.reduce((acc, value) => {
      return acc + value.total;
    }, 0);
  }

  restar(productoCart: IProductoCart) {
    if (productoCart.cantidad && productoCart.cantidad > 1) {

      let cart: ICart = this.localStorage.retrieve('productosCart');
      cart.cantidad--;
      cart.total = cart.total - productoCart.producto.precio;
      cart.productos.forEach(p => {
        if (p.producto.id === productoCart.producto.id) {
          p.cantidad--;
          p.total = p.cantidad * p.producto.precio;
        }
      });
      this.actualizarCart(cart);
    }
  }

  sumar(productoCart: IProductoCart) {
    debugger;
    if (productoCart.producto.cantidad > productoCart.cantidad) {

      let cart: ICart = this.localStorage.retrieve('productosCart');
      cart.cantidad++;
      cart.total = cart.total + productoCart.producto.precio;
      cart.productos.forEach(p => {
        if (p.producto.id === productoCart.producto.id) {
          p.cantidad++;
          p.total = p.cantidad * p.producto.precio;
        }
      });
      this.actualizarCart(cart);
    }
  }

  actualizarCart(cart: ICart) {
    if (cart && cart.productos) {
      this.dataSource = new MatTableDataSource<any>(cart.productos);
    }
    this.localStorage.store('productosCart', cart);
    this.sharedService.changeCart(cart);
  }

  deleteItemCart(itemCart: IProductoCart) {
    debugger;
    let cart: ICart = this.localStorage.retrieve('productosCart');
    if (cart && cart.productos) {
      const foundIndex = cart.productos.findIndex(x => x.producto.id === itemCart.producto.id);
      cart.productos.splice(foundIndex, 1);

      cart.cantidad = cart.cantidad - itemCart.cantidad;
      cart.total = cart.total - itemCart.total;
      this.actualizarCart(cart);
    }
  }

}