import { Injectable } from '@angular/core';
import { IOferta } from '../models/oferta.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SERVER_API_URL } from '../app.constants';

type EntityResponseType = HttpResponse<IOferta>;
type EntityArrayResponseType = HttpResponse<IOferta[]>;

@Injectable({
  providedIn: 'root'
})
export class OfertaService {

  public resourceUrl = SERVER_API_URL + 'api/ofertas';

  constructor(protected http: HttpClient) {}

  create(oferta: IOferta): Observable<EntityResponseType> {
    return this.http.post<IOferta>(this.resourceUrl, oferta, { observe: 'response' });
  }

  update(oferta: IOferta): Observable<EntityResponseType> {
    return this.http.put<IOferta>(this.resourceUrl, oferta, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IOferta>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(): Observable<EntityArrayResponseType> {
    return this.http.get<IOferta[]>(this.resourceUrl, { observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }
}
