import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IColeccion } from '../models/coleccion.model';
import { SERVER_API_URL } from '../app.constants';
import { createRequestOption } from '../shared/request-util';

type EntityResponseType = HttpResponse<IColeccion>;
type EntityArrayResponseType = HttpResponse<IColeccion[]>;

@Injectable({
  providedIn: 'root'
})
export class ColeccionService {
  public resourceUrl = SERVER_API_URL + 'api/colecciones';

  constructor(protected http: HttpClient) {}

  create(coleccion: IColeccion): Observable<EntityResponseType> {
    return this.http.post<IColeccion>(this.resourceUrl, coleccion, { observe: 'response' });
  }

  update(coleccion: IColeccion): Observable<EntityResponseType> {
    return this.http.put<IColeccion>(this.resourceUrl, coleccion, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IColeccion>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IColeccion[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  findByNemonico(nemonico: string): Observable<EntityResponseType> {
    return this.http.get<IColeccion>(`${this.resourceUrl}/nemonico/${nemonico}`, { observe: 'response' });
  }
}

