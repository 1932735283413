
import { IProductoCart } from './producto.cart.model';

export interface ICart {
  id?: number;
  productos?: IProductoCart[];
  cantidad?: number;
  total?: number;
 
}

export class Cart implements ICart {
  constructor(
    public id?: number,
    public productos?: IProductoCart[],
    public cantidad?: number,
    public total?: number
  ) {}
}
